export const stages = [
  { value: "Acquisition", selected: true },
  { value: "Activation" },
  { value: "Retention" },
  { value: "Revenue" },
  { value: "Referral" },
];
export const channels = [
  { value: "SEO" },
  { value: "Email outreach" },
  { value: "LinkedIn outreach" },
  { value: "Cold calling" },
  { value: "Partnerships" },
  { value: "Viral" },
];
export const statuses = [
  { value: "Idea", selected: true },
  { value: "Planned" },
  { value: "Live" },
  { value: "Completed" },
];

export const metrics = [
  {
    value: "revenue",
    label: "Revenue",
    type: "heading",
  },
  {
    value: "Annual Recurring Revenue (ARR)",
  },
  {
    value: "Average Order Value (AOV)",
  },
  {
    value: "Monthly Recurring Revenue (MRR)",
  },
  {
    value: "Average Revenue Per User (ARPU)",
  },
  {
    value: "Lifetime Value (LTV)",
  },
  {
    value: "Acquisition",
    type: "heading",
  },
  {
    value: "Customer Acquisition Cost (CAC)",
  },
  {
    value: "Conversion Rate",
  },
  {
    value: "Shopping Cart Abandonment Rate",
  },
  {
    value: "Cost Per Click (CPC)",
  },
  {
    value: "Cost Per Impression (CPM)",
  },
  {
    value: "Cost Per Lead (CPL)",
  },
  {
    value: "Cost Per Acquisition (CPA)",
  },
  {
    value: "Organic Traffic Growth",
  },
  {
    value: "Paid Traffic Growth",
  },
  {
    value: "Lead Generation Rate",
  },
  {
    value: "Click-Through Rate (CTR)",
  },
  {
    value: "customer",
    label: "Customer",
    type: "heading",
  },
  {
    value: "Activation Rate",
  },
  {
    value: "Retention Rate",
  },
  {
    value: "Churn Rate",
  },
  {
    value: "Repeat Purchase Rate",
  },
  {
    value: "Net Promoter Score (NPS)",
  },
  {
    value: "Customer Satisfaction Score (CSAT)",
  },
  {
    value: "Customer Effort Score (CES)",
  },
  {
    value: "engagement",
    label: "Engagement",
    type: "heading",
  },
  {
    value: "Pageviews",
  },
  {
    value: "Bounce Rate",
  },
  {
    value: "Session Duration",
  },
  {
    value: "Content Engagement Rate",
  },
  {
    value: "Social Media Engagement",
  },
  {
    value: "Email Open Rate",
  },
  {
    value: "Email Click Rate",
  },
  {
    value: "Video View Rate",
  },
  {
    value: "brand",
    label: "Brand",
    type: "heading",
  },
  {
    value: "Brand Awareness",
  },
  {
    value: "Brand Loyalty",
  },
  {
    value: "Share of Voice",
  },
  {
    value: "Social Media Mentions",
  },
  {
    value: "performance",
    label: "Performance",
    type: "heading",
  },
  {
    value: "Return on Investment (ROI)",
  },
  {
    value: "Return on Advertising Spend (ROAS)",
  },
  {
    value: "Marketing Qualified Leads (MQL)",
  },
  {
    value: "Sales Qualified Leads (SQL)",
  },
  {
    value: "Funnel Conversion Rates",
  },
  {
    value: "content",
    label: "Content",
    type: "heading",
  },
  {
    value: "Content Downloads",
  },
  {
    value: "Blog Post Engagement",
  },
  {
    value: "Video Engagement",
  },
  {
    value: "Webinar Attendance Rate",
  },
  {
    value: "technical",
    label: "Technical",
    type: "heading",
  },
  {
    value: "Website Traffic",
  },
  {
    value: "Mobile App Downloads",
  },
  {
    value: "App Engagement",
  },
  {
    value: "Software Usage",
  },
  {
    value: "Page Load Time",
  },
  {
    value: "advertising",
    label: "Advertising",
    type: "heading",
  },
  {
    value: "Ad Impressions",
  },
  {
    value: "Ad Engagement",
  },
  {
    value: "Quality Score",
  },
  {
    value: "Cost Per Conversion",
  },
  {
    value: "View Through Conversions",
  },
];
