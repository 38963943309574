<template>
  <div>
    <PageHeader
      title="Growth Experiments"
      sub-title="Plan, prioritize, and execute growth experiments to push your business forward."
    >
      <template #actions>
        <gm-button
          primary
          label="New experiment"
          icon="plus"
          @click="openNewExperimentModal"
          v-if="haveExperiments"
        />
        <Modal
          v-model="modalOpen"
          :dynamicComponent="NewExperimentView"
          size="large"
          noPadding
          @emittedToModal="handleModalClosed"
          @closed="handleModalClosed"
          :no-transition="modalTransitionDisabled"
        />
      </template>
    </PageHeader>

    <gm-page-container>
      <ExperimentList @click-new-experiment="openNewExperimentModal" />
    </gm-page-container>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useExperimentsStore } from "@/store/experiments";
import PageHeader from "@/components/PageHeader.vue";
import ExperimentList from "@/components/experiments/ExperimentList.vue";
import NewExperimentView from "@/views/experiments/NewExperimentView.vue";
import Modal from "@/components/Modal.vue";

/*
 * ####################################################################################################################
 * REACTIVE STATE
 * ####################################################################################################################
 */

const router = useRouter();
const modalOpen = ref(false);
const modalTransitionDisabled = ref(false);
const experimentsStore = useExperimentsStore();

/*
 * ####################################################################################################################
 * COMPUTED
 * ####################################################################################################################
 */

const haveExperiments = computed(() => experimentsStore.experiments.length > 0);
/*
 * ####################################################################################################################
 * METHODS
 * ####################################################################################################################
 */

function openNewExperimentModal(disabled = false) {
  modalTransitionDisabled.value = disabled;
  modalOpen.value = true;
  // If router doesn't have the query param, add it
  if (!router.currentRoute.value.query.add) {
    router.push({ query: { add: "experiment" } });
  }

  if (modalTransitionDisabled.value) {
    setTimeout(() => {
      modalTransitionDisabled.value = false;
    }, 1000);
  }
}

function handleModalClosed() {
  router.push({ query: null });
  modalOpen.value = false;
}

/*
 * ####################################################################################################################
 * WATCHERS
 * ####################################################################################################################
 */

// Watch the route to open the modal if the query param is present
watch(
  () => router.currentRoute.value.query.add,
  (val) => {
    if (val === "experiment") {
      openNewExperimentModal();
    }
  }
);

/*
 * ####################################################################################################################
 * LIFECYCLE
 * ####################################################################################################################
 */

onMounted(async () => {
  // Check if there is a query param for adding a new initiative
  if (router.currentRoute.value.query.add === "experiment") {
    openNewExperimentModal(true);
  }
});
</script>

<style></style>
