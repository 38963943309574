<template>
  <div class="flex h-full min-h-full relative">
    <div
      class="bg-brandDarkBlue-default h-full min-h-full w-96 px-8 py-8 text-white flex-shrink-0 hidden flex-col xl:flex"
    >
      <h2 class="text-3xl font-semibold mb-6">
        <span v-if="editMode">Edit experiment</span>
        <span v-else>New experiment</span>
      </h2>
      <p class="opacity-75">
        <span v-if="editMode"
          >Edit the experiment and click the Save Changes button at the bottom.</span
        >
        <span v-else>
          Build a new growth experiment to test a hypothesis and learn what works for your business.
        </span>
      </p>
    </div>
    <div class="h-full w-full overflow-auto">
      <div class="p-10 w-full flex flex-col gap-4">
        <gm-card overflow>
          <div class="p-6 flex flex-col gap-3">
            <div class="flex justify-between items-center mb-3">
              <h4 class="mb-0">Details</h4>
              <gm-button
                label="Clear form"
                icon="trash"
                icon-only
                size="small"
                secondary
                :disabled="buttonDisabled"
                @click="clearForm(true)"
              />
            </div>

            <gm-input
              v-model="title"
              type="text"
              label="Title"
              placeholder="Enter a descriptive title for this experiment"
              required
              :has-error="titleError"
              @focus="titleError = ''"
            />
            <gm-input
              v-model="description"
              type="textarea"
              label="Description"
              placeholder="Enter a short description for this experiment"
              :has-error="descriptionError"
              @focus="descriptionError = ''"
              rows="2"
            />
            <gm-input
              v-model="hypothesis"
              type="textarea"
              label="Hypothesis"
              placeholder="Enter the hypothesis you want to test"
              :has-error="hypothesisError"
              @focus="hypothesisError = ''"
              rows="4"
            />
            <div class="flex gap-4 w-full">
              <gm-dropdown
                full-width
                v-model="initiativeId"
                :options="initiatives"
                label="Initiative"
                class="flex-1 max-w-1/2"
                searchable
                placeholder="Select initiative"
                :disabled="initiativesLoading"
                :has-error="initiativeError"
                @focus="initiativeError = ''"
                @change="initiativeError = ''"
              />
              <gm-dropdown
                v-model="primaryMetric"
                :options="metricsList"
                label="Primary metric"
                class="flex-1 max-w-1/2"
                placeholder="Choose a metric"
                searchable
                :has-error="primaryMetricError"
                @focus="primaryMetricError = ''"
                @change="primaryMetricError = ''"
              />
              <gm-dropdown
                v-model="secondaryMetrics"
                :options="metricsListSecondary"
                label="Secondary metrics"
                class="flex-1 max-w-1/2"
                placeholder="Choose one or more metrics"
                searchable
                multiple
                :has-error="secondaryMetricsError"
                @focus="secondaryMetricsError = ''"
                @change="secondaryMetricsError = ''"
              />
            </div>
            <div class="flex gap-4 w-full">
              <gm-dropdown
                full-width
                v-model="stage"
                :options="stages"
                label="Stage"
                class="flex-1 max-w-1/2"
                :has-error="stageError"
                @focus="stageError = ''"
                @change="stageError = ''"
              />
              <gm-dropdown
                full-width
                v-model="channel"
                :options="channels"
                optional
                label="Channel"
                class="flex-1 max-w-1/2"
                searchable
                addable
                :has-error="channelError"
                @focus="channelError = ''"
                @change="channelError = ''"
              />
              <gm-input
                v-model="budget"
                type="currency"
                label="Budget"
                class="flex-1 max-w-1/2"
                :has-error="budgetError"
                @focus="budgetError = ''"
              />
            </div>
            <div class="flex gap-4 w-full">
              <gm-input
                v-model="startDate"
                type="date"
                label="Start date"
                class="flex-1 max-w-1/2"
                placeholder="Select date"
                required
                :has-error="startDateError"
                @focus="startDateError = ''"
                @change="startDateError = ''"
              />
              <gm-input
                v-model="endDate"
                type="date"
                label="End date"
                class="flex-1 max-w-1/2"
                placeholder="Select date"
                required
                :has-error="endDateError"
                @focus="endDateError = ''"
                @change="endDateError = ''"
              />
              <gm-dropdown
                full-width
                v-model="status"
                :options="statuses"
                label="Status"
                class="flex-1 max-w-1/2"
                :has-error="statusError"
                @focus="statusError = ''"
                @change="statusError = ''"
              />
            </div>
          </div>
        </gm-card>
        <gm-card overflow>
          <div class="p-6 flex flex-col gap-3">
            <div class="flex mb-3 flex-col">
              <h4 class="mb-3">ICE score: {{ iceScore }}</h4>
              <div class="flex gap-6">
                <div class="flex flex-col flex-1">
                  <p class="text-gray-500 text-sm mb-0 flex-1">
                    The ICE score is a prioritization framework that helps you decide which
                    experiments to run first.
                  </p>
                  <p>
                    <a
                      href="https://learningloop.io/glossary/ice-scoring-model"
                      target="_blank"
                      class="text-xs"
                    >
                      Learn more
                    </a>
                  </p>
                </div>

                <div class="flex-1 flex gap-3">
                  <gm-input
                    label="Impact"
                    v-model="impact"
                    type="number"
                    class="flex-1 max-w-1/3"
                    placeholder="0"
                    min="0"
                    max="10"
                    step="1"
                    required
                    note="All scores out of 10"
                    :has-error="impactError"
                    @focus="impactError = ''"
                  />
                  <gm-input
                    label="Confidence"
                    v-model="confidence"
                    type="number"
                    class="flex-1 max-w-1/3"
                    placeholder="0"
                    min="0"
                    max="10"
                    step="1"
                    required
                    :has-error="confidenceError"
                    @focus="confidenceError = ''"
                  />
                  <gm-input
                    label="Ease"
                    v-model="ease"
                    type="number"
                    class="flex-1 max-w-1/3"
                    placeholder="0"
                    min="0"
                    max="10"
                    step="1"
                    required
                    :has-error="easeError"
                    @focus="easeError = ''"
                  />
                </div>
              </div>
            </div>
          </div>
        </gm-card>
        <div class="flex-grow-0 mt-4">
          <gm-button
            :label="editMode ? 'Save changes' : 'Create experiment'"
            size="large"
            primary
            :disabled="buttonDisabled"
            :loading="formSubmitting"
            @click="submitForm"
            :success="buttonSuccess"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, nextTick, ref, defineEmits, watch } from "vue";
import { useRouter } from "vue-router";
import useInterfaceStore from "@/store/interface";
import useExperimentsStore from "@/store/experiments";
import useUserStore from "@/store/user";
import useInitiativesStore from "@/store/initiatives";
import { useNotify } from "@/plugins/useNotify";
import { useDialog } from "@/plugins/useDialog";
import { stages, metrics, channels, statuses } from "./dropdown-settings";

/**
 * ####################################################################################################################
 * EMITS & PROPS
 * ####################################################################################################################
 */
const emit = defineEmits(["emittedToModal"]);

/**
 * ####################################################################################################################
 * REACTIVE
 * ####################################################################################################################
 */

const router = useRouter();
const $notify = useNotify();
const $dialog = useDialog();
const interfaceStore = useInterfaceStore();
const experimentsStore = useExperimentsStore();
const userStore = useUserStore();
const initiativesStore = useInitiativesStore();
const initiativesLoading = ref(true); // whether initiatives are currently loading
const editMode = ref(false); // whether the form is in edit mode

// Form fields and state
const title = ref("");
const description = ref("");
const hypothesis = ref("");
const stage = ref("Acquisition");
const category = ref("");
const channel = ref("");
const primaryMetric = ref("");
const secondaryMetrics = ref([]);
const status = ref("Idea");
const budget = ref("");
const impact = ref(5);
const confidence = ref(5);
const ease = ref(5);
const startDate = ref("");
const endDate = ref("");
const initiativeId = ref(""); // the id of the initiative this experiment belongs to
const metricsList = JSON.parse(JSON.stringify(metrics)); // copy of the metrics object
const metricsListSecondary = JSON.parse(JSON.stringify(metrics)); // copy of the metrics object
const formSubmitting = ref(false); // whether the form is currently submitting
const buttonSuccess = ref(false); // whether the submit button should show a success state
const initialLoadState = {
  title: "",
  description: "",
  hypothesis: "",
  stage: "",
  category: "",
  channel: "",
  primaryMetric: "",
  secondaryMetrics: [],
  status: "",
  budget: "",
  impact: 5,
  confidence: 5,
  ease: 5,
  startDate: "",
  endDate: "",
  initiativeId: "",
};

// Form field errors. Could probably be done more cleanly but this works for now.
const titleError = ref("");
const descriptionError = ref("");
const hypothesisError = ref("");
const initiativeError = ref("");
const primaryMetricError = ref("");
const secondaryMetricsError = ref("");
const channelError = ref("");
const budgetError = ref("");
const startDateError = ref("");
const endDateError = ref("");
const stageError = ref("");
const statusError = ref("");
const impactError = ref("");
const confidenceError = ref("");
const easeError = ref("");

/**
 * ####################################################################################################################
 * COMPUTED
 * ####################################################################################################################
 */

// Calculate if form has unsaved changes
const formHasUnsavedChanges = computed(() => {
  return (
    title.value !== initialLoadState.title ||
    description.value !== initialLoadState.description ||
    hypothesis.value !== initialLoadState.hypothesis ||
    stage.value !== initialLoadState.stage ||
    category.value !== initialLoadState.category ||
    channel.value !== initialLoadState.channel ||
    primaryMetric.value !== initialLoadState.primaryMetric ||
    secondaryMetrics.value !== initialLoadState.secondaryMetrics ||
    status.value !== initialLoadState.status ||
    budget.value !== initialLoadState.budget ||
    impact.value !== initialLoadState.impact ||
    confidence.value !== initialLoadState.confidence ||
    ease.value !== initialLoadState.ease ||
    startDate.value !== initialLoadState.startDate ||
    endDate.value !== initialLoadState.endDate ||
    initiativeId.value !== initialLoadState.initiativeId
  );
});

// Check if any form fields have errors
const haveErrors = computed(() => {
  return (
    titleError.value ||
    descriptionError.value ||
    hypothesisError.value ||
    initiativeError.value ||
    stageError.value ||
    statusError.value ||
    primaryMetricError.value ||
    secondaryMetricsError.value ||
    channelError.value ||
    budgetError.value ||
    startDateError.value ||
    endDateError.value ||
    impactError.value ||
    confidenceError.value ||
    easeError.value
  );
});

const formCanBeSubmitted = computed(() => {
  return title.value && initiativeId.value && stage.value && status.value && !haveErrors.value;
});

// List of initiatives
const initiatives = computed(() => {
  return initiativesStore.initiatives.map((initiative) => {
    return {
      value: initiative._id,
      label: initiative.title,
    };
  });
});

// Check if all required fields are filled to enable the submit button
const buttonDisabled = computed(() => {
  return !formCanBeSubmitted.value || formSubmitting.value;
});

// ICE score
const iceScore = computed(() => {
  const score = (
    (Number(impact.value) + Number(confidence.value) + Number(ease.value)) /
    3
  ).toFixed(2);
  if (score < 0 || score > 10) {
    return "--";
  }

  return score;
});

/*
 * ####################################################################################################################
 * METHODS
 * ####################################################################################################################
 */

// Function to check the form for errors
async function checkForm() {
  return new Promise((resolve, reject) => {
    // Check if title is empty
    if (!title.value) {
      titleError.value = "Please enter a title";
    } else if (title.value.length < 5) {
      titleError.value = "Title must be at least 5 characters";
    } else if (title.value.length > 100) {
      titleError.value = "Title must be less than 100 characters";
    } else {
      titleError.value = "";
    }

    // Check if description is empty
    if (!description.value) {
      descriptionError.value = "Please enter a description";
    } else if (description.value.length < 5) {
      descriptionError.value = "Description must be at least 5 characters";
    } else if (description.value.length > 250) {
      descriptionError.value = "Description must be less than 250 characters";
    } else {
      descriptionError.value = "";
    }

    // If hypothesis is not empty, check if it's between 25 and 1000 characters
    if (hypothesis.value) {
      if (hypothesis.value.length < 25) {
        hypothesisError.value = "Hypothesis must be at least 25 characters";
      } else if (hypothesis.value.length > 1000) {
        hypothesisError.value = "Hypothesis must be less than 1000 characters";
      } else {
        hypothesisError.value = "";
      }
    } else {
      hypothesisError.value = "";
    }

    // Check if end date is earlier than start date
    if (startDate.value && endDate.value) {
      if (new Date(startDate.value) > new Date(endDate.value)) {
        endDateError.value = "End date must be later than start date";
      } else {
        endDateError.value = "";
      }
    } else {
      endDateError.value = "";
    }

    // Check if initiative is empty
    if (!initiativeId.value) {
      initiativeError.value = "Please select an initiative";
    } else {
      initiativeError.value = "";
    }

    // Check if stage is empty
    if (!stage.value) {
      stageError.value = "Please select a stage";
    } else {
      stageError.value = "";
    }

    // Check if status is empty
    if (!status.value) {
      statusError.value = "Please select a status";
    } else {
      statusError.value = "";
    }

    // Check the ICE scores are between 0 and 10
    if (impact.value < 0 || impact.value > 10) {
      impactError.value = "Must be between 0 and 10";
    } else {
      impactError.value = "";
    }

    if (confidence.value < 0 || confidence.value > 10) {
      confidenceError.value = "Must be between 0 and 10";
    } else {
      confidenceError.value = "";
    }

    if (ease.value < 0 || ease.value > 10) {
      easeError.value = "Must be between 0 and 10";
    } else {
      easeError.value = "";
    }

    nextTick(() => {
      resolve();
    });
  });
}

// Submit the form
async function submitForm() {
  // Check the form
  await checkForm();

  if (!haveErrors.value) {
    formSubmitting.value = true;
    const exp = {
      title: title.value,
      description: description.value,
      hypothesis: hypothesis.value,
      stage: stage.value,
      category: category.value,
      channel: channel.value,
      primaryMetric: primaryMetric.value,
      secondaryMetrics: secondaryMetrics.value,
      status: status.value,
      budget: budget.value,
      impact: impact.value,
      confidence: confidence.value,
      ease: ease.value,
      startDate: startDate.value,
      endDate: endDate.value,
      companyId: userStore.companyId,
      initiativeId: initiativeId.value,
    };

    try {
      // Send form data to the server
      if (editMode.value) {
        // Add the experiment ID to the object
        exp._id = experimentsStore.currentExperiment._id;
        // If in edit mode, update the experiment
        await experimentsStore.updateExperiment(exp);
      } else {
        // If not in edit mode, create a new experiment
        await experimentsStore.createExperiment(exp);
      }

      // If successful, show success state on the button for 3 seconds
      buttonSuccess.value = true;
      $notify(
        `Experiment "${title.value}" was successfully ${editMode.value ? "updated" : "created"}.`,
        "success"
      );

      setTimeout(() => {
        buttonSuccess.value = false;
      }, 1000);

      emit("emittedToModal", "closed");
    } catch (err) {
      // Handle any errors during form submission
      $notify(err.message || "An error occurred while submitting the form.", "error");
    } finally {
      formSubmitting.value = false;
    }
  } else {
    $notify("Please fix the errors in the form and try again.", "error");
  }
}

// Clear the form
function clearForm(showDialog) {
  function doFormClear() {
    title.value = "";
    description.value = "";
    hypothesis.value = "";
    stage.value = "";
    category.value = "";
    channel.value = "";
    primaryMetric.value = "";
    secondaryMetrics.value = [];
    status.value = "";
    budget.value = "";
    impact.value = 5;
    confidence.value = 5;
    ease.value = 5;
    startDate.value = "";
    endDate.value = "";
    initiativeId.value = "";
  }

  if (showDialog) {
    $dialog(
      "Please confirm",
      "This action will empty and reset all the form fields. No changes will be saved until you click the Save Changes button.",
      "confirm",
      "Confirm",
      () => {
        doFormClear();
      }
    );
  } else {
    doFormClear();
  }
}

/**
 * ####################################################################################################################
 * WATCHERS
 * ####################################################################################################################
 */

// Watch for changes to the formHasUnsavedChanges computed property
watch(formHasUnsavedChanges, (hasUnsavedChanges) => {
  interfaceStore.modalUnsavedChanges = hasUnsavedChanges;
});

/**
 * ####################################################################################################################
 * LIFECYCLE
 * ####################################################################################################################
 */

onMounted(async () => {
  // Fetch initiatives
  await initiativesStore.fetchInitiatives({ companyId: userStore.companyId });
  initiativesLoading.value = false;

  // Check if the form is in edit mode
  if (router.currentRoute.value.query.edit) {
    console.log("edit mode");
    editMode.value = true;

    // Get the currentExperiment from the store
    const experiment = experimentsStore.currentExperiment;

    // Set form fields to the experiment data
    title.value = experiment.title;
    description.value = experiment.description;
    hypothesis.value = experiment.hypothesis;
    stage.value = experiment.stage;
    category.value = experiment.category;
    channel.value = experiment.channel;
    primaryMetric.value = experiment.primaryMetric;
    secondaryMetrics.value = experiment.secondaryMetrics;
    status.value = experiment.status;
    budget.value = experiment.budget;
    impact.value = experiment.impact;
    confidence.value = experiment.confidence;
    ease.value = experiment.ease;
    startDate.value = experiment.startDate;
    endDate.value = experiment.endDate;
    initiativeId.value = experiment.initiativeId;

    // Save the initial state of the form
    initialLoadState.title = experiment.title;
    initialLoadState.description = experiment.description;
    initialLoadState.hypothesis = experiment.hypothesis;
    initialLoadState.stage = experiment.stage;
    initialLoadState.category = experiment.category;
    initialLoadState.channel = experiment.channel;
    initialLoadState.primaryMetric = experiment.primaryMetric;
    initialLoadState.secondaryMetrics = experiment.secondaryMetrics;
    initialLoadState.status = experiment.status;
    initialLoadState.budget = experiment.budget;
    initialLoadState.impact = experiment.impact;
    initialLoadState.confidence = experiment.confidence;
    initialLoadState.ease = experiment.ease;
    initialLoadState.startDate = experiment.startDate;
    initialLoadState.endDate = experiment.endDate;
    initialLoadState.initiativeId = experiment.initiativeId;
  }

  interfaceStore.modalUnsavedChanges = formHasUnsavedChanges.value;
});
</script>
