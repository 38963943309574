import { defineStore } from "pinia";
import * as experimentsActions from "./actions";

export const useExperimentsStore = defineStore("experiments", {
  state: () => {
    return {
      experiments: [],
      currentExperiment: null,
    };
  },
  actions: {
    async fetchExperiments(data: any) {
      await experimentsActions.fetchExperiments(this.$state, data);
    },
    async fetchExperiment(data: any) {
      await experimentsActions.fetchExperiment(this.$state, data);
    },
    async createExperiment(data: any) {
      await experimentsActions.createExperiment(this.$state, data);
    },
    async updateExperiment(data: any) {
      await experimentsActions.updateExperiment(this.$state, data);
    },
  },
});

export default useExperimentsStore;
