export async function fetchExperiments(state: any, payload: { [key: string]: any } = {}) {
  // Check if payload is provided and is not null
  if (!payload) {
    console.error("Payload is undefined or null");
    return;
  }

  // Construct the URL with parameters
  const params = new URLSearchParams(Object.entries(payload));
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/experiments?${params.toString()}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.experiments = data; // Assuming you have an experiments property in your state
    }
  } catch (err) {
    console.error("Error fetching experiments:", err);
  }
}

// Fetch a single experiment by ID
export async function fetchExperiment(state: any, experimentId: string) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/experiments/${experimentId}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const experiment = await response.json();

    if (experiment) {
      state.currentExperiment = experiment;
    }
  } catch (err) {
    console.error(`Error fetching experiment with ID ${experimentId}:`, err);
  }
}

// Create a new experiment
export async function createExperiment(state: any, payload: { [key: string]: any }) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/experiments`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.experiments.push(data);
    }
  } catch (err) {
    console.error("Error creating experiment:", err);
  }
}

// Update an experiment
export async function updateExperiment(state: any, payload: { [key: string]: any }) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/experiments/${payload._id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.currentExperiment = data;
    }
  } catch (err) {
    console.error("Error updating experiment:", err);
  }
}
