<template>
  <div class="flow-root min-w-full">
    <gm-spinner v-if="loading" size="large" />
    <div v-if="!loading && haveExperiments" class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
      <gm-slide-over
        v-model="experimentPanelOpen"
        panel-title="Experiment"
        @closed="handleCloseExperiment"
        action-button-label="Save experiment"
        @click-action-button="saveExperimentChanges"
        :action-button-loading="selectedExperimentActionButtonLoading"
        :action-button-disabled="selectedExperimentActionButtonDisabled"
      >
        <ExperimentEdit :experiment-id="selectedExperimentId" ref="experimentEditRef" />
      </gm-slide-over>
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <th>Name</th>
            <th>Stage</th>
            <th>Channel</th>
            <th>Main metric</th>
            <th>Status</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Budget</th>
          </thead>
          <tbody>
            <tr
              v-for="experiment in experiments"
              :key="experiment._id"
              class="cursor-pointer hover:bg-gray-50"
            >
              <td>
                <a
                  href="#"
                  class="font-medium hover:text-brand-500 hover:underline"
                  @click.prevent="openExperiment(experiment._id)"
                >
                  {{ experiment.title }}
                </a>
              </td>
              <td>
                {{ experiment.stage }}
              </td>
              <td>
                {{ experiment.channel }}
              </td>
              <td>
                {{ experiment.primaryMetric }}
              </td>
              <td>
                <StatusPicker
                  :status="toSentenceCase(experiment.status)"
                  :statuses="statuses"
                  :open="openStatusMenuId === experiment._id"
                  @toggle="toggleStatus(experiment)"
                  @change="changeStatus($event, experiment)"
                />
              </td>
              <td>
                <gm-input
                  type="date"
                  :modelValue="experiment.startDate"
                  @update:modelValue="(newDate) => updateStartDate(experiment, newDate)"
                  @clear="clearStartDate(experiment)"
                  placeholder="Not set"
                  date-format="M j, Y"
                  in-table
                  class="-ml-1.5"
                />
              </td>
              <td>
                <gm-input
                  type="date"
                  :modelValue="experiment.endDate"
                  @update:modelValue="(newDate) => updateEndDate(experiment, newDate)"
                  @clear="clearEndDate(experiment)"
                  placeholder="Not set"
                  date-format="M j, Y"
                  in-table
                  class="-ml-1.5"
                />
              </td>

              <td>
                <span class="text-xs">
                  {{ experiment.budget ? formatCurrency(experiment.budget) : null }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="!loading && !haveExperiments">
      <div v-if="haveInitiatives">
        <gm-empty-state
          title="No experiments"
          sub-title="Get started by creating a new experiment."
          button-text="New experiment"
          button-icon="plus"
          @button-click="$emit('clickNewExperiment')"
        >
          <template #icon>
            <BeakerIcon />
          </template>
        </gm-empty-state>
      </div>
      <div v-else>
        <gm-empty-state
          title="Whoa! You need to set up some initiatives first."
          sub-title="Experiments are tied to initiatives. Click the button below to go to your Growth Plan and set some up."
          button-text="Go to Growth Plan"
          button-icon="none"
          :button-primary="false"
          @button-click="goToGrowthPlan"
        >
          <template #icon> <BeakerIcon /> </template
        ></gm-empty-state>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, reactive, nextTick, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import StatusPicker from "@/components/StatusPicker.vue";
import { toSentenceCase, formatCurrency } from "@/utils/helpers";
import useUserStore from "@/store/user";
import useExperimentStore from "@/store/experiments";
import useInitiativesStore from "@/store/initiatives";
import { useNotify } from "@/plugins/useNotify";
import { BeakerIcon } from "@heroicons/vue/24/outline";
import ExperimentEdit from "@/components/experiments/ExperimentEdit.vue";

/*
 * ####################################################################################################################
 * EMITS AND PROPS
 * ####################################################################################################################
 */
const emit = defineEmits(["clickNewExperiment"]);

/*
 * ####################################################################################################################
 * REACTIVE STATE
 * ####################################################################################################################
 */

const $notify = useNotify();
const router = useRouter();
const userStore = useUserStore();
const experimentsStore = useExperimentStore();
const initiativesStore = useInitiativesStore();
const loading = ref(true);
const openStatusMenuId = ref(null);
const statuses = reactive([
  { label: "Idea", color: "gray" },
  { label: "Planned", color: "yellow" },
  { label: "Live", color: "green" },
  { label: "Completed", color: "blue" },
]);
const experimentPanelOpen = ref(false);
const selectedExperimentId = ref(null);
const selectedExperimentActionButtonLoading = ref(false);
const selectedExperimentActionButtonDisabled = ref(false);
const experimentEditRef = ref(null);

/*
 * ####################################################################################################################
 * COMPUTED
 * ####################################################################################################################
 */
const companyId = computed(() => userStore.companyId);
const experiments = computed(() => experimentsStore.experiments);
const haveExperiments = computed(() => experiments.value && experiments.value.length > 0);
const haveInitiatives = computed(
  () => initiativesStore.initiatives && initiativesStore.initiatives.length > 0
);

/*
 * ####################################################################################################################
 * METHODS
 * ####################################################################################################################
 */

// Status toggle
function toggleStatus(obj) {
  if (openStatusMenuId.value === obj._id) {
    openStatusMenuId.value = null;
  } else {
    openStatusMenuId.value = obj._id;
  }
}

// Status change
function changeStatus(newStatus, experiment) {
  // Update the relevant item in the experimentsStore experiments array
  const index = experimentsStore.experiments.findIndex((e) => e._id === experiment._id);
  experimentsStore.experiments[index].status = newStatus;

  // Close the status menu
  openStatusMenuId.value = null;
}

// Update start date
function updateStartDate(experiment, newDate) {
  // Update the relevant item in the experimentsStore experiments array
  const index = experimentsStore.experiments.findIndex((e) => e._id === experiment._id);
  experimentsStore.experiments[index].startDate = newDate;
}

// Update the end date
function updateEndDate(experiment, newDate) {
  // Update the relevant item in the experimentsStore experiments array
  const index = experimentsStore.experiments.findIndex((e) => e._id === experiment._id);
  experimentsStore.experiments[index].endDate = newDate;
}

// Clear the start date
function clearStartDate(experiment) {
  // Update the relevant item in the experimentsStore experiments array
  const index = experimentsStore.experiments.findIndex((e) => e._id === experiment._id);
  experimentsStore.experiments[index].startDate = null;
}

// Clear the end date
function clearEndDate(experiment) {
  // Update the relevant item in the experimentsStore experiments array
  const index = experimentsStore.experiments.findIndex((e) => e._id === experiment._id);
  experimentsStore.experiments[index].endDate = null;
}

// Go to the Growth Plan
function goToGrowthPlan() {
  router.push("/growth-plan");
}

// Open experiment
function openExperiment(experimentId) {
  selectedExperimentId.value = experimentId;
  // Add the id to the URL
  router.push(`/experiments/${experimentId}`);
  nextTick(() => {
    experimentPanelOpen.value = true;
  });
}

// Save experiment changes
async function saveExperimentChanges() {
  selectedExperimentActionButtonLoading.value = true;
  selectedExperimentActionButtonDisabled.value = true;
  await nextTick();
  if (experimentEditRef.value && experimentEditRef.value.submitForm) {
    await experimentEditRef.value.submitForm();
    selectedExperimentActionButtonLoading.value = false;
    selectedExperimentActionButtonDisabled.value = false;
    handleCloseExperiment();
  }
}

async function handleCloseExperiment() {
  experimentPanelOpen.value = false;
  selectedExperimentId.value = null;
  router.push("/experiments");
  await experimentsStore.fetchExperiments({ companyId: companyId.value });
}

/*
 * ####################################################################################################################
 * LIFECYCLE
 * ####################################################################################################################
 */

onMounted(async () => {
  // Make sure companyId is set. It it's not, wait for it to be set.
  if (!companyId.value) {
    await userStore.fetchUser();
  }

  // Fetch initiatives. This is needed because users can't create experiments without initiatives.
  await initiativesStore.fetchInitiatives({ companyId: companyId.value });

  try {
    await experimentsStore.fetchExperiments({ companyId: companyId.value });
  } catch (error) {
    console.log(error);
    $notify(error.message || "Error fetching experiments.", "error");
  } finally {
    loading.value = false;
  }

  // If the route has an :id param, open the experiment panel
  if (router.currentRoute.value.params.id) {
    openExperiment(router.currentRoute.value.params.id);
  }
});
</script>

<style lang="scss" scoped>
th {
  @apply top-0 z-10 border-b border-gray-200 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-600 backdrop-blur backdrop-filter table-cell;
}

td {
  @apply border-b border-gray-200 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 table-cell;
}
</style>
